import * as React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import type { PageRenderedItemData, PageRenderedSectionData } from "../rest.interface";
import { ContentRenderMode } from "./contentRenderMode";
import RenderContent from "./renderContent";
import RenderSection from "./renderSection";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
	item: PageRenderedItemData
}

export default function RenderSlider({item}: Props) {
	return (<>
		<Swiper
			className="swiper"
			modules={[Navigation, Pagination, Autoplay]}
			speed={500}
			loop={item.sliderLoop}
			navigation={item.sliderNavigation}
			autoplay={item.sliderAutoplay ? {
				delay: item.sliderDelay
			}: false}
			pagination={item.sliderPager}
		>
			<div className="swiper-wrapper">
				{item && item.page && item.page.content.map((section: PageRenderedSectionData, index) => {
					return (
						<SwiperSlide className="swiper-slide" key={'section' + index}>
							{!section?.reusable ?
								<RenderSection section={section} index={index} mode={ContentRenderMode.SLIDER}/> :
								<RenderContent content={section?.page?.content} mode={ContentRenderMode.SLIDER}/>
							}
						</SwiperSlide>
					);
				})}
			</div>
		</Swiper>

		{/*<Dump value={item.sliderPager}/>*/}
		{/*<Dump value={item.sliderNavigation}/>*/}
		{/*<Dump value={item.sliderAutoplay}/>*/}
		{/*<Dump value={item.sliderDelay}/>*/}
	</>);
}
