import { type AssetData, AssetMode } from "../mediaLibrary/asset.interface";
import { getImgUrl } from "../mediaLibrary/assetUtils";
import { type CommonSettings, type DimensionsInterface } from "../rest.interface";

export function getMarginClasses(settings: DimensionsInterface = null): string[] {
	let classes = [];
	classes = classes.concat(responsiveHelper(settings.marginTop, 'mt-', true));
	classes = classes.concat(responsiveHelper(settings.marginBottom, 'mb-', true));
	classes = classes.concat(responsiveHelper(settings.marginLeft, 'ml-', true));
	classes = classes.concat(responsiveHelper(settings.marginRight, 'mr-', true));
	return classes;
}

export function getPaddingClasses(settings: DimensionsInterface = null): string[] {
	let classes = [];
	classes = classes.concat(responsiveHelper(settings.paddingTop, 'pt-', true));
	classes = classes.concat(responsiveHelper(settings.paddingBottom, 'pb-', true));
	classes = classes.concat(responsiveHelper(settings.paddingLeft, 'pl-', true));
	classes = classes.concat(responsiveHelper(settings.paddingRight, 'pr-', true));
	return classes;

}

function getBackgroundImageUrl(asset: AssetData, settings: CommonSettings = null, webp = false): string {
	let width = settings?.backgroundImageWidth;
	if (!width) {
		width = 0;
	}

	let height = settings?.backgroundImageHeight;
	if (!height) {
		height = 0;
	}

	let	type = settings.backgroundImageCrop ? AssetMode.Thumb : AssetMode.Full;

	let filename = asset?.filename;
	if (webp) {
		filename = filename.substring(0, filename.lastIndexOf(('.' + asset?.fileExtension))) || filename;
		filename += '.webp';
	}

	return getImgUrl(type, asset?.id, filename, width, height);
}

export function getBgColorClass(settings: CommonSettings = null): string[] {
	let classes = [];

	if (settings?.backgroundClass) {
		classes.push(settings.backgroundClass)
	} else if (settings?.backgroundColor) {
		classes.push('bg-[' + settings.backgroundColor + ']');
	}

	return classes;
}

export function getBgImageClass(settings: CommonSettings = null): string[] {
	let classes = [];

	if (settings?.backgroundImageId) {
		if (settings?.backgroundSize) {
			classes.push(settings.backgroundSize);
		}
		if (settings?.backgroundRepeat) {
			classes.push(settings.backgroundRepeat);
		}
		if (settings?.backgroundPosition) {
			classes.push(settings.backgroundPosition);
		}
	}

	if (settings?.backgroundImage) {
		const imageSrc = getBackgroundImageUrl(settings.backgroundImage, settings);
		//const imageSrcWebp = getBackgroundImageUrl(settings.backgroundImage, settings, true);
		classes.push("bg-[url(" + imageSrc + ")]");
	}

	return classes;
}

export function responsiveHelper(responsive: object = null, prefix = '', numeric = false): string[] {
	let c = [];

	if (responsive) {
		Object.keys(responsive).forEach((breakpoint) => {
			let value = responsive[breakpoint];

			if (value) {
				value = value.toString();

				// console.log(breakpoint, this[breakpoint]);
				if (breakpoint === 'all') {
					breakpoint = '';
				} else {
					breakpoint = breakpoint + ':';
				}

				if (value !== '' && value !== null) {
					if (numeric) {
						if (value.startsWith('rem') || value.endsWith('px')) {
							c.push(breakpoint + prefix + '[' + value + ']');
						} else {
							value = parseInt(value);
							if (value >= 0) {
								c.push(breakpoint + prefix + value);
							} else {
								c.push(breakpoint + '-' + prefix + Math.abs(value));
							}
						}
					} else {
						c.push(breakpoint + prefix + value);
					}
				}
			}
		}, responsive);
	}

	// console.log(c);
	return c;
}

function prefixClass(className: string = '', prefix = ''): string {
	// cms-
	if (className.indexOf(':')) {
		let classParts = className.split(':');
		classParts[classParts.length - 1] = prefix + classParts[classParts.length - 1];
		return classParts.join(':');
	}

	if (className) {
		return prefix + className;
	}

	return '';
}

export function prefixClassArray(array = [], prefix = ''): string[] {
	return [...array.map((c) => prefixClass(c, prefix))];
}

