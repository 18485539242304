import * as React from 'react';
import { memo } from 'react';
import type { PageRenderedSectionData, PageSectionData } from "../rest.interface";
import { ContentRenderMode } from "./contentRenderMode";
import RenderSection from "./renderSection";

type Props = {
	content?: PageSectionData[],
	mode?: ContentRenderMode
}

function RenderContent({content, mode = ContentRenderMode.NORMAL}: Props) {
	return (<>
		{content && Array.isArray(content) && content.map((section: PageRenderedSectionData, index) => {
			if (section?.reusable) { // non reusable renders normal sections
				return <RenderContent key={'section' + index} content={section?.page?.content} mode={mode}/>;
			}

			// previously it was needed to ignore reusable sections to avoid infinite loop
			// mode !== ContentRenderMode.REUSE && mode !== ContentRenderMode.REUSE_BLOCK
			return <RenderSection key={'section' + index} section={section} index={index} mode={mode}/>;
		})}
	</>);
}

export default memo(RenderContent);
