import * as React from 'react';
import { getEnv } from "../helpers/env";

interface Props {
	label?: string;
	// rome-ignore lint/suspicious/noExplicitAny: <explanation>
	value?: any
}

function isObject(obj: any): boolean {
	return obj === Object(obj);
}

function isFunction(toCheck: any): boolean {
	return toCheck && {}.toString.call(toCheck) === "[object Function]";
}

function prepareObject(object: object): object {
	const newObject = {};

	Object.entries(object).map(([property, value]) => {
		if (isFunction(value)) {
			newObject[property] = `function ${value.name}()`;
		} else {
			newObject[property] = value;
		}
	});

	return newObject;
}

const Dump = ({label = '', ...props}: Props) => {
	// @ts-ignore
	//console.log(DUMP_ALLOWED);
	if (getEnv('PUBLIC_DUMP_ALLOWED') === "no") {
	 	return null;
	}

	function renderSingle(value: any): string {
		if (isObject(value)) {
			value = prepareObject(value);
		}

		return JSON.stringify(prepareObject(value), null, 2);
	}

	function renderProps() {
		if (Array.isArray(props)) {
			const toReturn: string[] = [];

			props.map(function (value) {
				toReturn.push(renderSingle(value));
			});

			return toReturn;
		}

		return renderSingle(props);
	}

	return (
		<div style={{ margin: "1rem 0" }}>
			<h3 style={{ fontFamily: "monospace" }} />
			<small>
				<pre style={{ background: "#f0f3f5", padding: ".5rem", color: "#000" }}>
					{label ? (<span><strong>{label || ""}</strong> ={" "}</span>) : null}
					{renderProps()}
				</pre>
			</small>
		</div>
	);
};

export default Dump;
