import * as React from 'react';
import { memo } from "react";
import type { PageRenderedItemData } from "../rest.interface.ts";
import NewsBox from "./newsBox.tsx";

interface Props extends React.ComponentPropsWithoutRef<"div"> {
	item: PageRenderedItemData
}

const LatestNews = ({item, ...otherProps}: Props) => {

	return (<>
		<div className="flex flex-wrap -mx-8 xl:-mx-16" {...otherProps}>
			{item.latestNews && Array.isArray(item.latestNews) && item.latestNews.map((news, index) =>
				<NewsBox news={news} key={"news" + index}/>
			)}
		</div>
	</>);
};

export default memo(LatestNews);
