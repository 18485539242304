import * as React from 'react';
import { memo } from "react";
import { clsx } from 'clsx';
import { useState } from 'react';
import '../../styles/widgets/accordion.css';

interface Props extends React.ComponentPropsWithoutRef<"div"> {
	className?: string,
	header: React.ReactNode,
	children: React.ReactNode,
}

const Accordion = ({className = '', header, children, ...otherProps}: Props) => {
	const [opened, setOpened] = useState(false);
	return (<>
		<div className={clsx("accordion", className, opened && 'iconOpened')} {...otherProps}>
			<div className="accordionHeader" onClick={(event) => {
				event.preventDefault();
				setOpened(!opened);
			}}>
				{header}
				<a href="#" className="iconOpenClose" title="+"></a>
			</div>

			<div className="accordionOverflow">
				{children}
			</div>
		</div>
	</>);
}

export default memo(Accordion);
