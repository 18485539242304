import { getEnv, hasEnv } from "../helpers/env.ts";
import { AssetMode } from "./asset.interface";

export function getImgUrl(mode: AssetMode, id: number, filename = '', width: number = 0, height: number = 0) {
	const prefix = (hasEnv('PUBLIC_ASSET_HOST') ? getEnv('PUBLIC_ASSET_HOST') : '');

	if (!hasEnv('PUBLIC_ASSET_HOST')) {
		console.info('missing PUBLIC_ASSET_HOST');
	}

	switch (mode) {
		case AssetMode.Full:
			return prefix + '/asset/full/' + id + "/" + width + '/' + height + '/' + filename;
		case AssetMode.Thumb:
			return prefix + '/asset/thumb/' + id + "/" + width + '/' + height + '/' + filename;
		case AssetMode.Media:
			return prefix + '/asset/media/' + id + "/" + filename;
	}
}
