export interface AssetData {
	id: number,
	objId: number,
	mime: string,
	filename: string,
	description: string,
	localFilename: string,
	creationDate: string,
	modificationDate: string,
	order: number,
	fileSize: number,
	fileExtension: string,
	linkDownload: string,
	linkView: string,
	width?: number,
	height?: number,
	isImage: boolean
	fileMimeExtension: string;
	colorBg: string;
}

export enum AssetMode {
	Thumb = "thumb",
	Full = "full",
	Media = "media",
}
