import type { AssetData } from "./mediaLibrary/asset.interface";

export interface ResponsiveValue<Type> {
	all?: Type,
	sm?: Type,
	md?: Type,
	lg?: Type,
	xl?: Type,
	"2xl"?: Type
}

export enum ModeType {
	all = "all",
	sm = "sm",
	md = "md",
	lg = "lg",
	xl = "xl",
	'2xl' = "2xl",
	'w-full' = "w-full",
}

export interface DimensionsInterface {
	marginTop?: ResponsiveValue<number|string>,
	marginBottom?: ResponsiveValue<number|string>,
	marginLeft?: ResponsiveValue<number|string>,
	marginRight?: ResponsiveValue<number|string>,

	paddingTop?: ResponsiveValue<number|string>,
	paddingBottom?: ResponsiveValue<number|string>,
	paddingLeft?: ResponsiveValue<number|string>,
	paddingRight?: ResponsiveValue<number|string>,
}

export interface CommonSettings extends DimensionsInterface {
	id?: string,
	class?: string,
	textAlign?: ResponsiveValue<string>,
	textColor: string;
	textClass: string;

	backgroundColor?: string,
	backgroundClass?: string
	backgroundRepeat?: string,
	backgroundPosition?: string,
	backgroundSize?: string

	backgroundImageId?: number,
	backgroundImageWidth?: number,
	backgroundImageHeight?: number,
	backgroundImageCrop?: boolean,
}

export enum ItemType {
	CODE = 'code',
	TEXT = 'text',
	REUSE = 'reuse',
	FAQ = 'faq',
	SLIDER = 'slider',
	CONTACT = 'contact',
	MAP = 'map',
	NEWS = 'news',
	SERVICE = 'service',
	IMAGE = 'image',
}

export interface PageItemData extends CommonSettings {
	type: ItemType,
	content: string,
	size?: ResponsiveValue<string>,
	order?: ResponsiveValue<number>,

	wrapper: string;
	title?: string

	sliderAutoplay?: boolean,
	sliderNavigation?: boolean,
	sliderPager?: boolean,
	sliderLoop?: boolean,
	sliderDelay?: number,

	editorState?: string,
}

export interface PageRenderedItemData extends PageItemData {
	page?: PageData,
	latestNews?: PageData[];
	rendered?: string,
	backgroundImage?: AssetData,
}

export enum SectionType {
	NORMAL = 'normal',
	REUSE = 'reuse',
}

export interface PageSectionData extends CommonSettings {
	type: SectionType,
	alignItems: ResponsiveValue<string>;
	justifyContent: ResponsiveValue<string>;
	items: PageItemData[],
	reusable?: number
	gap?: ResponsiveValue<number>,
	maxWidth: string,
	bleedBackgroundColor: boolean,
	bleedBackgroundImage: boolean,
}
export interface PageRenderedSectionData extends PageSectionData {
	page?: PageData,
	backgroundImage?: AssetData,
}

export interface PersonData {
	id?: number,
	email: string,
	firstname: string,
	surname: string,
	name?: string,
	registrationDate: string,
	optInFlag: boolean,
	logonCount: number,
	lastLogonDate: string,
	active: boolean,
}

export interface ObjData {
	id?: number,
	ownerId?: number,
	owner?: PersonData
	creationDate?: string
}

export interface MinimumListData {
	id?: number,
	name?: string,
}

export interface PageCategoryData extends MinimumListData {
	name: string,
	templateDefault?: string
	urlPrefix?: string,
	isHomepage?: boolean,
	isSeo?: boolean,
	order?: number,
}

export interface PageData {
	id?: number,
	obj?: ObjData,
	category?: PageCategoryData,
	order?: number,
	featured?: boolean,
	coverId?: number,
	cover?: AssetData
	headerId?: number,
	header?: AssetData
	modificationDate?: string,
	url?: string,
	absoluteUrl?: string,
	title: string,
	htmlTitle?: string,
	blurb: string
	metaKeywords?: string;
	metaDescription?: string,
	sitemapFreqId?: number;
	sitemapFreq?: string;
	sitemapPriorityId?: number;
	sitemapPriority?: string;
	noindex?: boolean,
	content: PageSectionData[],
	published: boolean,
	css: string
}

export interface PageErrors {
	objId?: boolean|string,
	ownerId?: boolean|string,
	categoryId?: boolean|string,
	order?: boolean|string,
	featured?: boolean|string,
	coverId?: boolean|string,
	headerId?: boolean|string,
	url?: boolean|string,
	title?: boolean|string,
	htmlTitle?: boolean|string,
	blurb?: boolean|string,
	metaKeywords?: boolean|string;
	metaDescription?: boolean|string,
	sitemapFreqId?: boolean|string;
	sitemapPriorityId?: boolean|string;
	noindex?: boolean|string,
	content?: boolean|string,
	published?: boolean|string,
	css?: boolean|string
	creationDate?: boolean|string;
}

export interface MenuItemData {
	id?: number,
	title: string,
	nodeId: number,
	menuId: number,
	typeId: number,
	type: string,
	parentId: number,
	productId?: number,
	categoryId?: number,
	pageId?: number,
	page?: PageData,
	url: string,
}

export interface MenuData {
	id?: number,
	name: string,
	url: string,
	maxDepth?: number,
	canDelete?: boolean
	items: MenuItemData[]
}
