import * as React from 'react';
import type { PageRenderedItemData } from "../rest.interface.ts";
import {APIProvider, Map, Marker, } from '@vis.gl/react-google-maps';

interface Props extends React.ComponentPropsWithoutRef<"div"> {
	item: PageRenderedItemData
}

const GoogleMap = ({item, ...otherProps}: Props) => {
	return (<>
		<APIProvider apiKey={'AIzaSyDx0lFoNzKhtSj-p95hdSElwjBnYRIspIs'}>
			<Map
				mapId="cbdbfcfb4268e82f"
				className="w-full h-[430px] md:h-[600px]"
				defaultZoom={13}
				defaultCenter={{ lat: 51.77292422069688, lng: -1.3582575423285699 }}
				disableDefaultUI={true}
			>
				<Marker
					position={{ lat: 51.77292422069688, lng: -1.3582575423285699 }}
					title={'Bluespires'}
				/>
			</Map>
		</APIProvider>
		<a href="https://maps.app.goo.gl/CrjArQL9pdSqT9yJ9" target="_blank" className="text-lg text-white">View on Google Maps</a>
	</>);
};

export default GoogleMap;
