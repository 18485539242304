
export function objectClone(obj: object) {
	return JSON.parse(JSON.stringify(obj));
}

export function isEmptyObject(obj = {}) {
	if (obj === null) {
		return false;
	}
	return (Object.keys(obj).length === 0 && obj.constructor === Object);
}

export function isObject(obj: any) { return obj === Object(obj); }

export function isFunction(functionToCheck: any) {
	return !!(functionToCheck && {}.toString.call(functionToCheck) === '[object Function]');
}

export const arrayRemoveItemAtIndex = (array: any[], index: number) => {
	return [
		...array.slice(0, index),
		...array.slice(index + 1)
	];
};

export const arrayInsertItemAtIndex = (array: any[], index: number, item: any) => {
	return [
		...array.slice(0, index),
		item,
		...array.slice(index)
	];
};

export function findElementWithIdInArray(array: any[], id: string|number, idProperty = 'id') {
	// console.log(array);
	// console.log(id, idProperty);

	if (Array.isArray(array)) {
		return array.find(function(item) {
			return (item[idProperty] == id);
		});
	}

	return null;
}

export function findElementWithIdInAssocArray(array: {}, id: string|number) {
	let toReturn = null;

	Object.keys(array).map(function(key) {
		//console.log(id, key, (key == id), array[key]);
		if (key == id) {
			toReturn = array[key];
		}
	});

	return toReturn;
}

export function getByteSize(bytes?: number, numberOfTenths: number = 1): string {
	if (null == bytes) {
		return null;
	}

	if (bytes === 0) {
		return '0 bytes';
	}

	let toReturn = '';

	if (bytes < 0) {
		bytes *= -1;
		toReturn = '-';
	}

	if (bytes === 1) {
		return toReturn + '1 byte';
	} else if (bytes < 1024) {
		return toReturn + bytes + ' bytes';
	} else if (bytes < (1024 * 1024)) {
		return toReturn + (bytes / 1024).toFixed(numberOfTenths) + ' kb';
	} else if (bytes < (1024 * 1024 * 1024)) {
		return toReturn + (bytes / (1024 * 1024)).toFixed(numberOfTenths + 1) + ' mb';
	}

	return toReturn + (bytes / (1024 * 1024 * 1024)).toFixed(numberOfTenths + 1) + ' gb';
}

export function isUrlRelative(url: string) {
	const r = new RegExp('^(?:[a-z+]+:)?//', 'i');
	return !r.test(url);
}

export function strip_tags(str: string, allow: string = '') {
	// making sure allow arg is a string containing only tags in lowercase (<a><b><c>)
	allow = (((allow || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
	const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
	const commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
	return str.replace(commentsAndPhpTags, '').replace(tags, function ($0: any, $1: string) {
		return allow.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
	});
}

export function decodeHTMLEntities(text: string) {
	const entities = [['amp','&'], ['apos','\''], ['#x27','\''], ['#x2F','/'], ['#39','\''], ['#47','/'], ['lt','<'], ['gt','>'], ['nbsp',' '], ['quot','"']];

	for (let i = 0, max = entities.length; i < max; ++i)
		text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

	return text;
}

export function isBrowser() {
	return typeof window !== 'undefined';
}

export function isJSON(input: string) {
	try {
		return JSON.parse(input) && !!input;
	} catch (e) {
		return false;
	}
}
