import * as React from 'react';
import { memo } from 'react';
import { clsx } from 'clsx';
import type { PageSectionData } from "../rest.interface";
import { ContentRenderMode } from "./contentRenderMode";
import {
	getBgColorClass, getBgImageClass,
	getMarginClasses, getPaddingClasses,
	prefixClassArray,
	responsiveHelper
} from "./renderFunctions";
import RenderItem from "./renderItem";

type Props = {
	section: PageSectionData
	mode?: ContentRenderMode,
	index?: number
}

function getSectionId(section: PageSectionData, index: number): string {
	return (section?.id) ? section.id : 'section' + index;
}

function getItemId(sectionId: string, index: number): string {
	return sectionId + '_' + index;
}

function renderOffsetAnchor(section: PageSectionData, index: number, mode = ContentRenderMode.NORMAL) {
	if (mode !== ContentRenderMode.SLIDER) {
		if (mode === ContentRenderMode.NORMAL || section?.id) {
			return (
				<div id={getSectionId(section, index)} className="offsetAnchor"/>
			);
		}
	}
	return null;
}

function RenderSection({section, index, mode = ContentRenderMode.NORMAL}: Props) {
	let sectionClass = ['section', 'flex', 'justify-center'];
	let sectionInnerClass = ['flex', 'flex-wrap', 'flex-grow', 'w-full'];
	let itemLayoutClass = [];
	let flexClass = [];

	if (section?.maxWidth) {
		if (section?.maxWidth !== 'w-full') {
			sectionInnerClass.push('max-w-screen-' + section.maxWidth);
		}
	}

	if (section?.class) {
		sectionInnerClass.push(section.class);
	}

	if (section?.gap) {
		itemLayoutClass = itemLayoutClass.concat(responsiveHelper(section.gap, 'pl-', true));
		itemLayoutClass = itemLayoutClass.concat(responsiveHelper(section.gap, 'pr-', true));

		flexClass = flexClass.concat(responsiveHelper(section.gap, '-ml-', true));
		flexClass = flexClass.concat(responsiveHelper(section.gap, '-mr-', true));
	}

	if (section?.textAlign) {
		sectionInnerClass = sectionInnerClass.concat(responsiveHelper(section.textAlign));
	}

	if (section?.alignItems) {
		sectionInnerClass = sectionInnerClass.concat(responsiveHelper(section.alignItems));
	}

	if (section?.justifyContent) {
		sectionInnerClass = sectionInnerClass.concat(responsiveHelper(section.justifyContent));
	}

	if (section.bleedBackgroundImage) { // if image bleeds add the image style to whole section
		sectionClass = sectionClass.concat(getBgImageClass(section));
	} else {
		sectionInnerClass = sectionInnerClass.concat(getBgImageClass(section));
	}

	if (section.bleedBackgroundColor) { // if color bleeds add the color class to whole section
		sectionClass = sectionClass.concat(getBgColorClass(section));
	} else {
		sectionInnerClass = sectionInnerClass.concat(getBgColorClass(section));
	}

	if (section?.textClass) {
		sectionInnerClass.push(section.textClass);
	} else if (section.textColor) {
		sectionInnerClass.push('text-[' + section.textColor + ']');
	}

	sectionClass = sectionClass.concat(getMarginClasses(section));

	if (mode !== ContentRenderMode.SLIDER) {
		sectionClass.push('h-full');
	}

	if (mode !== ContentRenderMode.REUSE_BLOCK) {
		sectionInnerClass = sectionInnerClass.concat(getPaddingClasses(section));
	}

	return (<>
		{/*<Dump label="sectionClass" value={sectionClass}/>*/}
		{/*<Dump label="sectionInnerClass" value={sectionInnerClass}/>*/}
		{/*<Dump label="itemLayoutClass" value={itemLayoutClass}/>*/}
		{/*<Dump label="flexClass" value={flexClass}/>*/}

		{renderOffsetAnchor(section, index, mode)}
		<section className={clsx(prefixClassArray(sectionClass))}>
			<div className={clsx(prefixClassArray(sectionInnerClass), prefixClassArray(flexClass))}>
				{section && section.items && section.items.map((item, indexItem) =>
					<RenderItem
						key={indexItem}
						item={item}
						itemId={getItemId(getSectionId(section, index), indexItem)}
						itemLayoutClass={itemLayoutClass}
					/>
				)}
			</div>
		</section>
	</>);
}

export default memo(RenderSection);
